import React from 'react'

import DeploymentUpdate from '../DeploymentUpdate'

const DeploymentOverview = ({
    id,
}: { id: number }) => {
    return (
        <DeploymentUpdate
            id={id}
            disabled
        />
    )
}

export default DeploymentOverview
