import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'

import AlLeasePricingCreate from './AlLeasePricingCreate'
import AlLeasePricingOverview from './AlLeasePricingOverview'
import tableConfig from './AlLeasePricing.config'
import request from './AlLeasePricing.requests'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const AlLeasePricing = () => {
    const tabs: SidebarTab[] = [{
        url: 'al-lease-pricing',
        label: 'Al Lease Pricing',
        Component: AlLeasePricingOverview,
    }]

    return (
        <SkyNetTable
            name={tableConfig.name}
            createForm={AlLeasePricingCreate}
            tabs={tabs}
            moduleName={request.domainName}
            defaultTab="data"
            tableControls={tableControls}
            tableConfig={tableConfig}
        />
    )
}

export default AlLeasePricing
