import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import useTabsByPermission from 'app/hooks/useTabsByPermission/useTabsByPermission'
import useHasPermission from 'app/hooks/useHasPermission'

import DeploymentCreate from './DeploymentCreate'
import DeploymentRequests from './Deployments.request'
import DeploymentOverview from './DeploymentOverview'
import tableConfig from './Deployments.config'
import DeploymentUpdate from './DeploymentUpdate'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearSorting,
]

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: DeploymentOverview,
}

const Deployments = () => {
    const gatewayCreate = useHasPermission(['gateway_create'])
    const gatewayUpdate = useHasPermission(['gateway_update'])

    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: {
                url: 'data-update',
                label: 'Data Update',
                Component: DeploymentUpdate,
            },
            permission: gatewayUpdate,
        },
    ])

    return (
        <SkyNetTable
            name="Deployment"
            customUrl={DeploymentRequests.all}
            tableControls={tableControls}
            tableConfig={tableConfig}
            showSearch
            createForm={gatewayCreate ? DeploymentCreate : undefined}
            tabs={tabs}
            defaultTab="overview"
        />
    )
}

export default Deployments
