import {
    RequestConfig,
    RequestForDomainForm,
} from 'app/types/request.types'

type GatewayRequests = {
    domainName: string,
    all: string,
    filterRequest: ({
        params: any,
    }) => RequestConfig,
    get: ({
        id: number,
    }) => RequestConfig,
    update: RequestForDomainForm<{id: number}>,
    create: RequestForDomainForm<undefined>,
    archive: ({
        id: number,
    }) => RequestConfig,
    restore: ({
        id: number,
    }) => RequestConfig,
}

const request: GatewayRequests = {
    domainName: 'Deployment',
    all: 'gateway/deployment/filter-request/all',
    filterRequest: ({
        params,
    }) => {
        return {
            url: 'gateway/deployment/filter-request/all',
            method: 'POST',
            ...params,
        }
    },
    get: ({
        id,
    }) => {
        return {
            url: `gateway/deployment/${id}`,
        }
    },
    update: {
        name: 'Deployment',
        request: ({
            id,
        }) => {
            return {
                url: `gateway/deployment/${id}`,
                method: 'PUT',
                domainName: 'Deployment',
            }
        },
    },
    create: {
        name: 'Deployment',
        request: () => {
            return {
                url: 'gateway/deployment',
                method: 'POST',
                domainName: 'Deployment',
            }
        },
    },
    archive: ({
        id,
    }) => {
        return {
            url: `gateway/deployment/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `gateway/deployment/${id}/restore`,
            method: 'PATCH',
        }
    },
}

export default request
