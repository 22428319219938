import {
    makeStyles,
} from 'app/tss'

const gateway = 'gateway'
const location = 'location'
const loggers = 'loggers'
const connectionInfo = 'connectionInfo'
const contacts = 'contacts'

const useStyles = makeStyles()(() => {
    return {
        gridWrapperWithLoggers: {
            display: 'grid',
            gridGap: 20,
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridTemplateAreas: `
                "${gateway} ${location}"
                "${gateway} ${contacts}"
                "${connectionInfo} ${loggers}"
            `,
        },
        location: {
            gridArea: location,
        },
        gateway: {
            gridArea: gateway,
        },
        loggers: {
            gridArea: loggers,
        },
        contacts: {
            gridArea: contacts,
        },
        remark: {
            gridColumnEnd: 'span 2',
        },
    }
})

export default useStyles
