import {
    makeStyles,
} from 'app/tss'

const laneInformation = 'laneInformation'
const positioning = 'positioning'
const reverseLogistics = 'reverseLogistics'
const proformaInvoice = 'proformaInvoice'

const useStyles = makeStyles()((theme) => {
    return {
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(2),
            gridTemplateAreas: `
                "${laneInformation} ${laneInformation} ${laneInformation} ${laneInformation}"
                "${proformaInvoice} ${proformaInvoice} ${proformaInvoice} ${proformaInvoice}"
                "${positioning} ${positioning} ${reverseLogistics} ${reverseLogistics}"
                `,
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
        laneInformation: {
            gridArea: laneInformation,
        },
        proformaInvoice: {
            gridArea: proformaInvoice,
        },
        positioning: {
            gridArea: positioning,
        },
        reverseLogistics: {
            gridArea: reverseLogistics,
        },
        actions: {
            paddingTop: theme.spacing(2),
            display: 'flex',
        },
        button: {
            paddingRight: 10,
        },
    }
})

export default useStyles
