import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'

export enum ActivityRecordType {
    MEETING = 'Meeting',
    CALL = 'Call'
}

export type ActivityRecordTable = {
    id: number,
    changedOn: string,
    companiesCompanyName: string[],
    organizer: string,
    participants: string[],
    subject: string,
    type: ActivityRecordType,
    happenedOn: string,
    durationInMinutes: number,
    isFollowUpNeeded: boolean,
}

export type ActivityRecord = {
    id: number,
    subject: string,
    type: ActivityRecordType,
    agenda: string,
    outcomes: string,
    durationInMinutes: number,
    happenedOn: string,
    companies: Company[],
    participants: { id: number }[],
    changedOn?: string,
    organizer: { id: number },
    supportingDocuments?: { id: number }[],
    archived: boolean,
    isFollowUpNeeded: boolean,
}
