const options = {
    height: '575',
    colors: [
        '#BCEEFF',
        '#A9D6E5',
        '#89C2D9',
        '#61A5C2',
        '#549BBB',
        '#468FAF',
        '#2C7DA0',
        '#2A6F97',
        '#013A63',
        '#012A4A',
    ],
    timeline: {
        colorByRowLabel: true,
    },
    avoidOverlappingGridLines: false,
    hAxis: {
        format: 'MMM dd, HH:mm',
    },
    alternatingRowStyle: false,
}

export default options
