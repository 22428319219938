import {
    RequestConfig, SelectorConfig,
} from 'app/types/request.types'

type Request = {
    get: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    skyMindSyncUpdate: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    archive: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    restore: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    update: ({
        data,
    }) => RequestConfig,
    create: ({
        data,
    }) => RequestConfig,
    duplication: ({
        data,
    }: {
        data: Record<string, any>
    }) => RequestConfig,
    selector: SelectorConfig<{ companyName: string, id: string }[]>,
    requestParentsSubsidiaries: ({
        id,
    }: {
        id: number,
    }) => RequestConfig,
    getAll?: (data?: Record<string, any>) => RequestConfig,
}

const request: Request = {
    get: ({
        id,
    }) => {
        return {
            url: `company/${id}`,
        }
    },
    skyMindSyncUpdate: ({
        id,
    }) => {
        return {
            url: `company/${id}/skymind-sync`,
            method: 'PATCH',
        }
    },
    archive: ({
        id,
    }) => {
        return {
            url: `company/${id}/archive`,
            method: 'PATCH',
        }
    },
    restore: ({
        id,
    }) => {
        return {
            url: `company/${id}/restore`,
            method: 'PATCH',
        }
    },
    update: ({
        data,
    }) => {
        return {
            url: 'company',
            method: 'PUT',
            data,
        }
    },
    create: ({
        data,
    }) => {
        return {
            url: 'company',
            method: 'POST',
            data,
        }
    },
    duplication: ({
        data,
    }) => {
        return {
            url: 'company/duplicate-check',
            method: 'POST',
            data,
        }
    },
    selector: {
        url: 'company/filter-request/selector',
        convertion: (companies) => {
            return companies.map(({
                companyName, id,
            }) => {
                return {
                    value: id,
                    label: companyName,
                    id,
                }
            })
        },
    },
    getAll: (data = {}) => {
        return {
            url: 'company/filter-request/selector',
            method: 'POST',
            data,
        }
    },
    requestParentsSubsidiaries: ({
        id,
    }) => {
        return {
            url: 'company/filter-request/all',
            method: 'POST',
            includeFilters: {
                parentCompanyId: [id],
            },
        }
    },
}

export default request
