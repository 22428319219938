import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()((theme) => {
    return {
        numberOfTrucks: {
            '&&': {
                width: '50%',
                paddingRight: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        numberOfVans: {
            '&&': {
                width: '50%',
                paddingLeft: theme.spacing(1),
                boxSizing: 'border-box',
                display: 'inline-block',
            },
        },
        gridWrapper: {
            display: 'grid',
            gap: theme.spacing(2),
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    }
})

export default useStyles
