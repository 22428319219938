import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'

import {
    Deployment,
} from './Deployment.types'

const config: SkyNetTableConfig<keyof Deployment> = {
    name: 'DeploymentsTable',
    tabs: [{
        name: 'All',
        url: 'all',
    }],
    fields: [
        {
            name: 'deploymentName',
            labelKey: 'Deployment.deploymentName',
            reduced: true,
            minWidth: '300px',
        },
        {
            name: 'createdOn',
            labelKey: 'Deployment.createdOn',
            width: '300px',
        },
        {
            name: 'createdBy',
            labelKey: 'Deployment.createdBy',
            width: '300px',
        },
    ],
}

export default config
