import {
    makeStyles,
} from 'app/tss'

const laneGroup = 'laneGroup'
const consignee = 'consignee'
const destination = 'destinatin'
const origin = 'origin'
const positioning = 'positioning'
const customerQuotation = 'customerQuotation'
const customerExecution = 'customerExecution'
const laneInformation = 'laneInformation'
const reverseLogistics = 'reverseLogistics'
const proformaInvoice = 'proformaInvoice'

const useStyles = makeStyles()((theme) => {
    return {
        buttonContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
        },
        gridWrapper: {
            display: 'grid',
            gridGap: theme.spacing(1.5),
            gridTemplateAreas: `
                "${laneGroup} ${laneGroup} ${customerQuotation} ${consignee}"
                "${customerExecution} ${laneInformation} ${laneInformation} ${laneInformation}"
                "${proformaInvoice} ${proformaInvoice} ${proformaInvoice} ${proformaInvoice}"
                "${origin} ${positioning} ${positioning} ${positioning}"
                "${destination} ${reverseLogistics} ${reverseLogistics} ${reverseLogistics}"
            `,
            gridTemplateColumns: 'repeat(4, 1fr)',
            [theme.breakpoints.down('xl')]: {
                gridTemplateAreas: `
                    "${laneGroup} ${laneGroup}"
                    "${customerQuotation} ${consignee}"
                    "${customerExecution} ${laneInformation}"
                    "${laneInformation} ${laneInformation}"
                    "${proformaInvoice} ${proformaInvoice}"
                    "${destination} ${destination}"
                    "${positioning} ${positioning}"
                    "${positioning} ${positioning}"
                `,
                gridTemplateColumns: 'repeat(2, 1fr)',
            },
        },
        customerExecution: {
            gridArea: customerExecution,
        },
        customerQuotation: {
            gridArea: customerQuotation,
        },
        consignee: {
            gridArea: consignee,
        },
        originScBackup: {
            gridRowEnd: 'span 2',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
            },
        },
        destination: {
            gridArea: destination,
        },
        origin: {
            gridArea: origin,
        },
        positioning: {
            gridArea: positioning,
        },
        laneInformation: {
            gridArea: laneInformation,
        },
        proformaInvoice: {
            gridArea: proformaInvoice,
        },
        laneGroup: {
            gridArea: laneGroup,
        },
        reverseLogistics: {
            gridArea: reverseLogistics,
        },
        defaultReverseLogisticsDefaultBy: {
            gridColumnEnd: 'span 5',
            gridRowEnd: 'span 3',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
                gridColumnEnd: 'auto',
            },
        },
        fwdImport: {
            gridRowEnd: 'span 6',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
            },
        },
        fwdImportBackup: {
            gridRowEnd: 'span 6',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
            },
        },
        fwdExportBackup: {
            gridRowEnd: 'span 6',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
            },
        },
        originSeaport: {
            gridRowEnd: 'span 2',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
            },
        },
        destSeaport: {
            gridRowEnd: 'span 2',
            [theme.breakpoints.down('xl')]: {
                gridRowEnd: 'auto',
            },
        },
    }
})

export default useStyles
