import {
    DTOCardFormField,
} from 'app/types/form.types'

import {
    Deployment,
} from './Deployment.types'

const getFields = ({
    disabled,
}: {
    disabled: boolean,
}): DTOCardFormField<keyof Deployment>[] => {
    return [{
        id: 'deployment',
        title: 'Deployment',
        className: 'deployment',
        fields: [
            {
                id: 'column1',
                fields: [{
                    name: 'deploymentName',
                    labelKey: 'Deployment.deploymentName',
                    componentName: 'InputSingleline',
                    required: true,
                    disabled,
                }],
            },
            {
                id: 'column2',
                className: 'onInfo',
                fields: [
                    {
                        name: 'createdOn',
                        labelKey: 'Deployment.createdOn',
                        componentName: 'DateTimeSelect',
                        disabled: true,
                    },
                    {
                        name: 'createdBy',
                        componentName: 'InputSingleline',
                        labelKey: 'Deployment.createdBy',
                        disabled: true,
                    },
                ],
            },
        ],
    }]
}

export default getFields
