import {
    SkyNetTableConfig,
} from 'app/shared-components/SkyNetTable'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    AddressCategory,
} from 'app/types/enums'
import {
    Gateway,
} from './Gateways.types'
import {
    GatewayBoxConfiguration, GatewayDataPlan, GatewayStatus, GatewayType,
} from './Gateway.enums'

const config: SkyNetTableConfig<keyof Gateway> = {
    name: 'GatewaysTable',
    tabs: [
        {
            name: 'Gateways',
            url: 'all',
        },
        {
            name: 'Excluded',
            url: 'excluded',
        },
    ],
    fields: [
        {
            name: 'gatewayType',
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayType,
            filterField: 'gatewayType',
            filterType: FilterType.Array,
            reduced: true,
            width: '148px',
        },
        {
            name: 'gatewayImeiMac',
            width: '160px',
        },
        {
            name: 'gatewayStatus',
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayStatus,
            filterField: 'gatewayStatus',
            filterType: FilterType.Array,
            width: '148px',
        },
        {
            name: 'loggerConnections',
            width: '128px',
        },
        {
            name: 'isConnectedLora',
            componentName: 'CloudIconBoolean',
            filterField: 'isConnectedLora',
            filterType: FilterType.Array,
            width: '128px',
        },
        {
            name: 'lastConnectedOn',
            componentName: 'TemperatureAge',
            width: '148px',
        },
        {
            name: 'locationLocationName',
            reduced: true,
            filterField: 'locationLocationName',
            width: '380px',
            filterType: FilterType.Array,
        },
        {
            name: 'locationCity',
            labelKey: 'Gateway.locationCity',
            filterField: 'locationCity',
            width: '180px',
            filterType: FilterType.Array,
        },
        {
            name: 'locationPortOfReferenceIataCode',
            labelKey: 'LocationView.portOfReference',
            filterField: 'locationPortOfReferenceIataCode',
            width: '180px',
            filterType: FilterType.Array,
        },
        {
            name: 'gatewayLocationCategories',
            componentName: 'EnumList',
            allowedValuesEnum: AddressCategory,
            width: '320px',
        },
        {
            name: 'area',
            filterField: 'area',
            filterType: FilterType.Array,
            width: '160px',
        },
        {
            name: 'gatewayDeploymentName',
            filterField: 'gatewayDeploymentName',
            labelKey: 'Deployment.deploymentName',
            filterType: FilterType.Array,
            width: '220px',
        },
        {
            name: 'gatewayBoxConfiguration',
            filterField: 'gatewayBoxConfiguration',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayBoxConfiguration,
            width: '180px',
        },
        {
            name: 'gatewayConnectionType',
            filterField: 'gatewayConnectionType',
            filterType: FilterType.Array,
            componentName: 'EnumValue',
            allowedValuesEnum: GatewayDataPlan,
            width: '180px',
        },
        {
            name: 'remark',
            width: '380px',
        },
    ],
}

export default config
