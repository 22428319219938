import useDelayedLoadOptions, {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import requests from 'app/Apps/ContactManagement/Companies/Companies.request'
import {
    useCallback,
} from 'react'
import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'

export default (): {
    loadOptions: (options: DelayedLoadOptions) => Promise<any>,
    conversion: (c: Company[]) => {
        value: number,
        id: number,
        label: string
    }[]
} => {
    const conversion = useCallback((companies = []) => {
        return companies.map(({
            companyName, id,
        }) => {
            return {
                value: id,
                label: companyName,
                id,
            }
        })
    }, [])

    const loadOptions = useDelayedLoadOptions(requests.selector)

    return {
        loadOptions,
        conversion,
    }
}
