import {
    DTOCardFormField, FieldInCard,
} from 'app/types/form.types'
import {
    allowedDurationValues,
} from './utils'
import {
    ActivityRecord, ActivityRecordType,
} from '../activityRecords.types'

type Fields = keyof ActivityRecord

const getParticipantsLabel = ({
    contactName,
}) => { return contactName }

const getTitle = ({
    disabled, exists,
}: {
    disabled?: boolean,
    exists?: boolean
}) => {
    if (disabled) return 'Activity Record Details'
    if (exists) return 'Update Activity Record Details'
    return 'New Activity Record'
}

const getFields = ({
    getAllowedValues,
    exists,
    organizerName,
    companies,
    participantsTypes,
    disabled,
}:{
    getAllowedValues: (v: Record<string, any>) => Record<string, any>,
    exists: boolean
    organizerName?: string,
    companies?: number[],
    participantsTypes: string[],
    disabled?: boolean
}): DTOCardFormField<Fields>[] => {
    const organizerField: FieldInCard<Fields> = {
        name: 'organizer',
        labelKey: 'ActivityRecords.organizer',
        componentName: exists ? 'UserObjectSelector' : 'InputSingleline',
        value: exists ? undefined : organizerName,
        disabled: true,
    }
    const changedOnField: FieldInCard<Fields> = {
        name: 'changedOn',
        labelKey: 'ActivityRecords.changedOn',
        componentName: 'DateTimeSelect',
        disabled: exists,
        hidden: !exists,
        className: 'changedOn',
    }

    return [
        {
            title: getTitle({
                exists, disabled,
            }),
            className: 'general',
            fields: [
                {
                    name: 'type',
                    labelKey: 'ActivityRecords.type',
                    componentName: 'EnumSelector',
                    required: true,
                    useDropdown: false,
                    allowedValues: getAllowedValues(ActivityRecordType),
                    className: 'type',
                },
                {
                    name: 'subject',
                    labelKey: 'ActivityRecords.subject',
                    componentName: 'InputSingleline',
                    required: true,
                },
                {
                    name: 'happenedOn',
                    labelKey: 'ActivityRecords.happenedOn',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                },
                {
                    name: 'supportingDocuments',
                    labelKey: 'Accounts.supportingDocuments',
                    componentName: 'AttachmentsObjectSelector',
                    className: 'attachments',
                },
                {
                    name: 'companies',
                    labelKey: 'ActivityRecords.companyName',
                    componentName: 'MultipleCompaniesSelector',
                    required: true,
                    className: 'company',
                },
                {
                    name: 'durationInMinutes',
                    labelKey: 'ActivityRecords.duration',
                    componentName: 'EnumSelector',
                    allowedValues: allowedDurationValues,
                    noSort: true,
                },
                {
                    name: 'participants',
                    labelKey: 'ActivityRecords.participants',
                    componentName: 'MultipleContactObjectSelector',
                    required: true,
                    disabled: disabled || !companies,
                    includeFilters: {
                        isProspect: [
                            true,
                            false,
                        ],
                    },
                    filters: {
                        companyAndTypeFilter: {
                            skycellCompany: true,
                            companyId: companies,
                            type: participantsTypes,
                        },
                    },
                    getValueLabel: getParticipantsLabel,
                    className: 'participants',
                    disableCache: true,
                },
                organizerField,
                changedOnField,
                {
                    name: 'isFollowUpNeeded',
                    componentName: 'Checkbox',
                    className: 'followUpNeeded',
                    labelKey: 'ActivityRecord.followUpNeeded',
                },
            ],
        },
        {
            title: 'Details',
            className: 'details',
            fields: [
                {
                    name: 'agenda',
                    labelKey: 'ActivityRecord.agenda',
                    componentName: 'LongText',
                    required: true,
                    rows: 10,
                },
                {
                    name: 'outcomes',
                    labelKey: 'ActivityRecord.outcomes',
                    componentName: 'LongText',
                    required: true,
                    rows: 10,
                },
            ],
        },
    ]
}

export default getFields
