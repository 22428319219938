import {
    useCallback,
} from 'react'
import {
    useQueryClient,
} from '@tanstack/react-query'

import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'
import DeploymentRequests from '../Deployments.request'
import {
    Deployment,
} from '../Deployment.types'

const useGetDeployment = (id) => {
    const queryClient = useQueryClient()

    const invalidate = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [RequestKeys.getDeployment],
        })
    }, [queryClient])

    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Deployment>({
        key: RequestKeys.getDeployment,
        params: {
            id,
        },
        requestFunc: RequestFn.getEntity(DeploymentRequests.get),
        enabled: Boolean(id),
    })

    return {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    }
}

export default useGetDeployment
