import React, {
    useMemo, useState, useEffect, useCallback,
} from 'react'

import ChipSelect from 'app/shared-components/ChipSelect'

import useGetCompaniesById from 'app/Apps/ContactManagement/Companies/hooks/useGetCompaniesById'
import {
    Company,
} from 'app/Apps/ContactManagement/Companies/Companies.types'
import useGetCompanyRequests from '../CompanySelector/hooks/useGetCompanyRequests'

type Props = {
    value?: Company[],
    onChange: (newValue: Company[]) => void,
    title?: string,
    className?: string,
    disabled?: boolean,
    required?: boolean,
    disableCache?: boolean,
}

const defaultProps: Partial<Props> = {
    value: [],
    title: 'companies',
    disabled: false,
    required: false,
    className: '',
    disableCache: false,
}

const MultipleCompaniesSelector = ({
    value,
    onChange,
    title,
    disabled,
    required,
    className,
    disableCache,
}: Props) => {
    const [
        state,
        setState,
    ] = useState<Company[]>(null)
    const {
        loadOptions,
        conversion,
    } = useGetCompanyRequests()

    // we need to do the request only once
    const companiesIds = useMemo(() => {
        return state === null && value.map(({
            id,
        }) => {
            return id
        })
    }, [
        value,
        state,
    ])

    const {
        data: companies,
        isDataReady,
    } = useGetCompaniesById(companiesIds)

    useEffect(() => {
        if (isDataReady) {
            setState(companies.length ? conversion(companies) : [])
        }
    }, [
        companies,
        conversion,
        isDataReady,
    ])

    const handleChange = useCallback((items: {value: number, id: number, label: string}[]) => {
        setState(() => {
            onChange(items)
            return items
        })
    }, [onChange])

    return (
        <div className={className}>
            <ChipSelect
                title={title}
                value={state ?? []}
                onChange={handleChange}
                loadOptions={loadOptions}
                showTitleOptions
                disabled={disabled}
                required={required}
                cacheOptions={!disableCache}
                menuShouldBlockScroll
                menuPortalTarget={document.body}
            />
        </div>
    )
}

MultipleCompaniesSelector.defaultProps = defaultProps

export default MultipleCompaniesSelector
