import {
    Lane,
} from '../../lanes.types'
import incoTermAdditionalTextMap from './incoTermAdditionalTextMap'

const getIncoTermAdditionalText = (lane: Lane) => {
    if (incoTermAdditionalTextMap[lane?.incoTerm]) {
        return incoTermAdditionalTextMap[lane?.incoTerm](lane)
    }

    return null
}

export default getIncoTermAdditionalText
