import {
    DTOCardFormField, NestedField,
} from 'app/types/form.types'
import {
    LeaseType, LocationType,
} from 'app/types/enums'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'

import {
    DeliveryServiceType, Order,
    TransportModeRoad,
} from '../../orders.types'
import {
    PositioningSetupType, PositioningSelection,
} from './PositioningSetup.types'

type FormFields = PositioningSetupType & Order

const getFields = ({
    transportModeForwarder,
    forwarderPositioningId,
    forwarderPositioningSelection,
    getAllowedValues,
    isDeliveryRoad,
    leaseType,
    switchForwarderContacts,
    order,
}: {
    transportModeForwarder: TransportModeRoad,
    getAllowedValues: (enumType: any) => Record<string, string>,
    isDeliveryRoad: boolean,
    forwarderPositioningId: number,
    forwarderPositioningSelection: PositioningSelection,
    leaseType: LeaseType,
    switchForwarderContacts: boolean,
    order: Order,
}): DTOCardFormField<keyof FormFields>[] => {
    const transportModeForwarderField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'transportModeForwarder',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        labelKey: 'Order.transportModeForwarder',
    }]

    const transportModeRoadField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        labelKey: 'Order.transportModeRoad',
    }]

    const freightForwarderField: NestedField<keyof PositioningSetupType>[] = [{
        name: 'freightForwarder',
        componentName: 'CompanyObjectSelector',
        required: true,
        labelKey: 'OrderByNumberView.freightForwarder',
    }]

    const numberOfVansTracksFields: NestedField<keyof PositioningSetupType>[] = [
        {
            name: 'numberOfTrucks',
            componentName: 'IntegerInput',
            className: 'numberOfTrucks',
            labelKey: 'CustomerTransportCreateDto.numberOfTrucks',
        },
        {
            name: 'numberOfVans',
            componentName: 'IntegerInput',
            className: 'numberOfVans',
            labelKey: 'CustomerTransportCreateDto.numberOfVans',
        },
    ]

    const forwarderFields: NestedField<keyof PositioningSetupType>[] = [
        {
            name: 'forwarderPositioningSelection',
            componentName: 'EnumSelector',
            noSort: true,
            allowedValues: getAllowedValues(PositioningSelection),
            labelKey: 'CustomerTransportCreateDto.fwdPosSelection',
        },
        {
            name: 'forwarderPositioning',
            componentName: 'LocationObjectSelector',
            filterAddressMainCategory: AddressMainCategory.SERVICECENTER,
            disabled: !isDeliveryRoad
            || forwarderPositioningSelection !== PositioningSelection.OTHER,
            required: true,
            labelKey: 'LaneOpsInformationDto.fwdPos',
        },
        ...switchForwarderContacts ? [{
            name: 'forwarderPositioningContacts',
            componentName: 'MultipleContactByLocationObjectSelector',
            existedForm: false,
            required: true,
            selectedLocation: forwarderPositioningId,
            labelKey: 'LaneOpsInformationDto.fwdPosContacts',
        }] as NestedField<keyof PositioningSetupType>[] : [{
            name: 'forwarderPositioningContacts',
            componentName: 'MultipleContactObjectSelector',
            disabled: true,
            labelKey: 'LaneOpsInformationDto.fwdPosContacts',
        }] as NestedField<keyof PositioningSetupType>[],
    ]

    return [
        {
            id: 'positioningSetup',
            className: 'positioningSetup',
            title: 'General',
            fields: [
                {
                    id: 'column_1',
                    fields: [
                        {
                            name: 'originAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            value: order?.originAirport,
                            disabled: true,
                            labelKey: 'Order.originAirport',
                        },
                        {
                            name: 'destinationAirport',
                            componentName: 'LocationObjectSelector',
                            filterLocationType: LocationType.AIRPORT,
                            value: order?.destinationAirport,
                            disabled: true,
                            labelKey: 'Order.destinationAirport',
                        },
                        {
                            name: 'leaseType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(LeaseType),
                            disabled: true,
                            labelKey: 'Order.leaseType',
                        },
                        {
                            name: 'orderReceived',
                            componentName: 'DateTimeZonedSelect',
                            required: true,
                            labelKey: 'Order.orderReceived',
                        },
                    ],
                },
                {
                    id: 'column_2',
                    fields: [
                        {
                            name: 'deliveryServiceType',
                            componentName: 'EnumSelector',
                            allowedValues: getAllowedValues(DeliveryServiceType),
                            disabled: true,
                            labelKey: 'Order.deliveryServiceType',
                        },
                        ...isDeliveryRoad ? transportModeRoadField : [],
                        ...isDeliveryRoad ? transportModeForwarderField : [],
                        ...transportModeForwarder === TransportModeRoad.FTL && isDeliveryRoad
                            ? numberOfVansTracksFields
                            : [],
                        ...isDeliveryRoad ? [{
                            name: 'estimatedPickup',
                            componentName: 'DateTimeZonedSelect',
                            labelKey: 'CustomerTransportCreateDto.estimatedPickup',
                        }] as NestedField<keyof PositioningSetupType>[] : [],
                    ],
                },
                {
                    id: 'column_3',
                    fields: [
                        ...isDeliveryRoad ? forwarderFields : [],
                        ...leaseType === LeaseType.AIRLINE_LEASE
                            ? freightForwarderField
                            : [],
                    ],
                },
                {
                    id: 'column_4',
                    fields: [
                        {
                            name: 'mawbNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.mawbNumber',
                        },
                        {
                            name: 'hawbNumber',
                            componentName: 'InputSingleline',
                            labelKey: 'CustomerTransportCreateDto.hawbNumber',
                        },
                        {
                            name: 'customerReference',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.customerReference',
                        },
                        {
                            name: 'personInCharge',
                            componentName: 'InputSingleline',
                            labelKey: 'Order.personInCharge',
                        },
                    ],
                },
            ],
        },
        {
            id: 'requiredContainers',
            className: 'requiredContainers',
            title: 'Required Containers',
            fields: [
                {
                    id: 'column_requiredContainers_1',
                    fields: [{
                        name: 'requiredContainers',
                        componentName: 'RequiredContainersByProductType',
                        allowedProductTypes: order?.allowedProductTypes,
                        value: order?.requiredContainers,
                        disabled: true,
                        labelKey: 'OrderByNumberView.requiredContainers',
                    }],
                },
                {
                    id: 'column_requiredContainers_2',
                    fields: [{
                        name: 'allowedProductTypes',
                        componentName: 'AllowedContainerTypes',
                        value: order?.allowedProductTypes,
                        noLabel: true,
                    }],
                },
                {
                    id: 'column_requiredContainers_3',
                    fields: [{
                        name: 'requestedHandover',
                        componentName: 'InputSingleline',
                        value: order?.requestedHandover,
                        disabled: true,
                        labelKey: 'OrderByNumberView.requestedHandover',
                    }],
                },
                {
                    id: 'column_requiredContainers_3',
                    fields: [{
                        name: 'leaseStartTimestamp',
                        componentName: 'DateTimeZonedSelect',
                        value: order?.billing.leaseStartTimestamp,
                        disabled: true,
                        labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
                    }],
                },
            ],
        },
    ]
}

export default getFields
