import useRequest, {
    RequestKeys,
    RequestFn,
} from 'app/hooks/useRequest'

import {
    Company,
} from '../Companies.types'
import config from '../Companies.request'

export default (ids: number[]) => {
    const {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    } = useRequest<Company[]>({
        key: RequestKeys.getCompanies,
        params: {
            includeFilters: {
                id: ids,
            },
        },
        requestFunc: RequestFn.getEntity(config.getAll, (promise) => {
            return promise.then((resp) => {
                return resp?.data?.items
            })
        }),
        keepPreviousData: true,
        enabled: Boolean(ids.length),
    })

    return {
        data,
        isDataReady,
        isFetching,
        isError,
        error,
    }
}
