import React, {
    useCallback,
} from 'react'

import Typeahead from 'app/shared-components/Typeahead'
import useGetDeployment from 'app/Apps/Sensors/Deployments/hooks/useGetDeployment'

import useDeploymentLoadOptions from './hooks/useDeploymentLoadOptions'

const defaultProps = {
    name: '',
    value: undefined,
    title: 'Deployment Name',
    disabled: false,
    className: '',
    required: false,
}

const DeploymentSelector = ({
    name,
    value,
    onChange,
    title,
    className,
    disabled,
    required,
}: {
    name?: string,
    value?: {
        id: number,
    },
    onChange: (value: {id: number}) => void,
    title?: string,
    disabled?: boolean,
    className?: string,
    required?: boolean,
}) => {
    const {
        data: labelData,
    } = useGetDeployment(value?.id)

    const loadOptions = useDeploymentLoadOptions()

    const onChangeValue = useCallback((newValue) => {
        const convertedValue = newValue ? {
            id: newValue,
        } : undefined

        onChange(convertedValue)
    }, [onChange])

    return (
        <Typeahead
            name={name}
            title={title}
            className={className}
            onChange={onChangeValue}
            loadOptions={loadOptions}
            labelData={labelData}
            labelField="deploymentName"
            value={value?.id}
            disabled={disabled}
            isRequired={required}
        />
    )
}

DeploymentSelector.defaultProps = defaultProps

export default DeploymentSelector
