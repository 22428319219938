import React, {
    useCallback,
} from 'react'

import useDomainObjectContext from 'app/Apps/DomainObject/hooks/useDomainObjectContext'

import DeploymentRequests from '../Deployments.request'
import DeploymentForm from '../DeploymentForm'

const DeploymentCreate = () => {
    const {
        selectDomainObject,
    } = useDomainObjectContext()

    const onSuccess = useCallback(({
        id,
    }) => {
        selectDomainObject(id)
    }, [selectDomainObject])

    return (
        <DeploymentForm
            onSuccess={onSuccess}
            requestParam={DeploymentRequests.create.request()}
        />
    )
}

export default DeploymentCreate
