import React, {
    useMemo,
} from 'react'
import noop from 'lodash/noop'

import TimelineChart from 'app/shared-components/TimelineChart'
import FullScreen from 'app/shared-components/FullScreen'

import options from './optionsChart'
import useStyles from './GatewayTimelineChart.styles'

const columns = [
    {
        type: 'string',
        id: 'Gateway',
    },
    {
        type: 'date',
        id: 'Start',
    },
    {
        type: 'date',
        id: 'End',
    },
]

const defaultProps = {
    setTemperatureChartFullscreen: noop,
    temperatureChartFullscreen: false,
}

const GatewayTimelineChart = ({
    sensorData,
    temperatureChartFullscreen,
    setTemperatureChartFullscreen,
}: {
    sensorData: {
        t: string,
        d: (number|string)[],
    }[],
    temperatureChartFullscreen?: boolean,
    setTemperatureChartFullscreen?: (value: boolean) => void,
}) => {
    const {
        classes,
    } = useStyles()

    const chartData: (string | number | Date)[][] = useMemo(() => {
        return sensorData?.map(({
            d, t,
        }, index) => {
            const itemChart = [
                d[0],
                new Date(t),
            ]

            if (index < sensorData.length - 1 && sensorData[index + 1].d[0] === d[0]) {
                return [
                    ...itemChart,
                    new Date(sensorData[index + 1].t),
                ]
            }

            return [
                ...itemChart,
                new Date(t),
            ]
        })
    }, [sensorData])

    if (chartData?.length) {
        return (
            <FullScreen
                setTemperatureChartFullscreen={setTemperatureChartFullscreen}
            >
                <TimelineChart
                    value={chartData}
                    options={options}
                    columns={columns}
                    className={temperatureChartFullscreen ? classes.fullscreen : classes.chart}
                />
            </FullScreen>
        )
    }

    return (<div data-testid="empty-state-msg">There is no Gateway data for the selected period.</div>)
}

GatewayTimelineChart.defaultProps = defaultProps

export default GatewayTimelineChart
