import {
    DTOCardFormField,
    NestedField,
} from 'app/types/form.types'
import {
    ProductType,
} from 'app/types/enums'

import {
    BookingDetailsStepType,
} from '../../createCustomerTransportForm.types'

const getFields = ({
    isDeliveryRoad,
    allowedProductTypes,
    disabled,
}: {
    isDeliveryRoad: boolean,
    allowedProductTypes: ProductType[],
    disabled: boolean,
}): DTOCardFormField<keyof BookingDetailsStepType>[] => {
    return [{
        title: 'Booking Details',
        className: 'bookingDetails',
        id: 'bookingDetails',
        fields: [
            {
                id: 'bookingDetails-column1',
                fields: [
                    {
                        name: 'orderReceived',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'Order.orderReceived',
                    },
                    ...isDeliveryRoad ? [{
                        name: 'requestedDelivery',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'Order.requestedDelivery',
                    }] as NestedField<keyof BookingDetailsStepType>[] : [{
                        name: 'requestedPickup',
                        componentName: 'DateTimeZonedSelect',
                        required: true,
                        labelKey: 'OrderStep.requestedPickup',
                    }] as NestedField<keyof BookingDetailsStepType>[],
                ],
            },
            {
                id: 'bookingDetails-column2',
                fields: [{
                    name: 'customerReference',
                    componentName: 'InputSingleline',
                    required: true,
                    labelKey: 'Order.customerReference',
                }],
            },
            {
                id: 'bookingDetails-column3',
                fields: [{
                    name: 'leaseStartTimestamp',
                    componentName: 'DateTimeZonedSelect',
                    required: true,
                    labelKey: 'CustomerTransportCreateDto.leaseStartTimestamp',
                }],
            },

            {
                id: 'bookingDetails-column4',
                fields: [{
                    name: 'requiredContainers',
                    componentName: 'RequiredContainersByProductType',
                    allowedProductTypes,
                    disabled: !allowedProductTypes || disabled,
                    required: true,
                    labelKey: 'Order.requiredContainers',
                }],
            },
        ],
    }]
}

export default getFields
