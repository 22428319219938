import React, {
    useMemo,
} from 'react'

import StatusHandler from 'app/shared-components/StatusHandler'

import PositioningSetup from './PositioningSetup'
import {
    DeliveryServiceType, Order,
} from '../../orders.types'
import useGetLaneOpsInformation from '../hooks/useGetLaneOpsInformation'
import useGetPositioningSetup from './hooks/useGetPositioningSetup'
import useGetOrderByNumber from '../../hooks/useGetOrderByNumber'

const PositioningSetupContainer = ({
    id: orderNumber,
}: {id: string}) => {
    const {
        data,
        isDataReady,
        isError,
        error,
        isFetching,
        invalidate,
    } = useGetPositioningSetup(orderNumber)

    const {
        data: order = {} as Order,
    } = useGetOrderByNumber(orderNumber)

    const {
        lane,
        isDataReady: isDataReadyLane,
    } = useGetLaneOpsInformation({
        selectedLaneId: data?.laneId,
        enabled: data?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD,
    })

    const isDeliveryRoad: boolean = useMemo(() => {
        return data?.deliveryServiceType === DeliveryServiceType.DELIVERY_ROAD
    }, [data?.deliveryServiceType])

    return (
        <StatusHandler
            isSuccess={isDataReady && (!isDeliveryRoad || isDataReadyLane)}
            isFetching={isFetching}
            isError={isError}
            error={error}
        >
            <PositioningSetup
                data={data}
                lane={lane}
                order={order}
                orderNumber={orderNumber}
                isDeliveryRoad={isDeliveryRoad}
                onSuccess={invalidate}
            />
        </StatusHandler>
    )
}

export default PositioningSetupContainer
