import React, {
    useCallback,
    useMemo,
} from 'react'
import isEmpty from 'lodash/isEmpty'

import {
    useHistory,
} from 'react-router-dom'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import DTOSpec from 'app/services/dtoSpec'
import tableConfig from './GatewayContacts.config'

type Props = {
    value?: {
        id: number,
    }[],
}
type ContactsFilter = {
    id: number[]
}

const defaultProps: Partial<Props> = {
    value: [],
}

const GatewayContacts = ({
    value,
}: Props) => {
    const history = useHistory()

    const filters: ContactsFilter = useMemo(() => {
        return {
            id: value?.map((contact) => {
                return contact.id
            }),
        }
    }, [value])

    const onRowClickContact = useCallback(({
        id: contactId,
    }) => {
        history.push(`/apps/contactmanagement/contacts/all/edit/${contactId}/overview`)
    }, [history])

    return (
        <DataContentWrapper
            isEmpty={isEmpty(value)}
            emptyDataReplacer="There is no data in this table yet."
        >
            <SkyNetTable
                name="Contact"
                filter={filters}
                simple
                onRowClick={onRowClickContact}
                customUrl={DTOSpec.Contact.url}
                tableConfig={tableConfig}
                noDetails
            />
        </DataContentWrapper>
    )
}

GatewayContacts.defaultProps = defaultProps

export default GatewayContacts
