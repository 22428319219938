import {
    FormPart,
} from 'app/types/form.types'

import {
    IncoTerm, InvoiceSettingsType, Lane, PreliminaryInvoiceCurrency,
} from '../../lanes.types'

const proformaInvoiceFields = (
    getAllowedValues: (enumType: Record<string, string>) => Record<string, string>,
): FormPart<keyof (Lane & InvoiceSettingsType
)> => {
    return {
        title: 'Proforma Invoice',
        className: 'proformaInvoice',
        fields: [
            {
                id: 'column1',
                fields: [
                    {
                        name: 'incoTerm',
                        componentName: 'Select',
                        allowedValues: getAllowedValues(IncoTerm),
                        labelKey: 'LaneOpsInformationDto.incoTerm',
                    },
                    {
                        name: 'incoTermAdditionalText',
                        componentName: 'InputSingleline',
                        labelKey: 'LaneOpsInformationDto.incoTermAdditionalText',
                    },
                    {
                        name: 'preliminaryInvoiceCurrency',
                        componentName: 'EnumSelector',
                        allowedValues: getAllowedValues(PreliminaryInvoiceCurrency),
                        useDropdown: true,
                        labelKey: 'LaneOpsInformationDto.preliminaryInvoiceCurrency',
                    },
                ],
            },
            {
                id: 'column2',
                fields: [
                    {
                        name: 'hsCodeContainer',
                        componentName: 'InputSingleline',
                        required: true,
                        labelKey: 'LaneOpsInformationDto.hsCodeContainer',
                    },
                    {
                        name: 'hsCodeLogger',
                        componentName: 'InputSingleline',
                        labelKey: 'LaneOpsInformationDto.hsCodeLogger',
                    },
                    {
                        name: 'scheduleBNumber',
                        componentName: 'InputSingleline',
                        labelKey: 'LaneOpsInformationDto.scheduleBNumber',
                    },
                ],
            },
            {
                id: 'column3',
                fields: [
                    {
                        name: 'invoiceFootNote',
                        componentName: 'LongText',
                        labelKey: 'LaneOpsInformationDto.invoiceFootNote',
                    },
                    {
                        name: 'consigneeAddress',
                        componentName: 'LongText',
                        required: true,
                        labelKey: 'LaneOpsInformationDto.consigneeAddress',
                    },
                ],
            },
        ],
    }
}

export default proformaInvoiceFields
