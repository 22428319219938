import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'

import Requests from 'app/Apps/Sensors/Deployments/Deployments.request'

const useDeploymentLoadOptions = () => {
    return useDelayedLoadOptions({
        url: Requests.all,
        params: {
            sortField: 'createdOn',
            sortType: 'desc',
            rows: 25,
            start: 0,
        },
    })
}

export default useDeploymentLoadOptions
