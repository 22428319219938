import React, {
    useCallback,
    useMemo,
    useState,
} from 'react'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import {
    useAuth,
} from 'app/Auth'

import useFieldsWithClassName from 'app/hooks/useFieldsWithClassName'
import useHasPermission from 'app/hooks/useHasPermission'
import FormWithControls from 'app/shared-components/FormWithControls'
import useEnumValues from 'app/hooks/useEnumValues'
import {
    RequestConfig,
} from 'app/types/request.types'

import getActivityRecordFields from './ActivityRecord.fields'
import AccountManagementRequests from '../../RelationshipsManagement.request'
import {
    ActivityRecord,
} from '../activityRecords.types'
import useStyles from './ActivityRecord.style'

type Props = {
    data?: ActivityRecord,
    onSuccess?: (props: any) => void,
    onCancel?: (...args: any[]) => void,
    requestParam: RequestConfig,
    disabled?: boolean,
    customButtons?: JSX.Element[],
}

const defaultProps: Partial<Props> = {
    data: {} as ActivityRecord,
    onSuccess: noop,
    onCancel: noop,
    disabled: false,
    customButtons: [],
}

const ActivityRecordForm = ({
    data,
    onSuccess,
    onCancel,
    requestParam,
    disabled,
    customButtons,
}: Props) => {
    const {
        classes,
    } = useStyles()
    const getAllowedValues = useEnumValues()
    const exists = !!data?.id
    const {
        user,
    } = useAuth()
    const [
        value,
        setValue,
    ] = useState(data)
    const hasRelationOpsPermission = useHasPermission(['relationships_ops'])
    const hasRelationSalesPermission = useHasPermission(['relationships_sales'])

    const currentUserName = useMemo(() => {
        if (!user) {
            return ''
        }
        const {
            firstName,
            lastName,
        } = user

        return `${firstName} ${lastName}`
    }, [user])

    const participantsTypes = useMemo(() => {
        if (hasRelationOpsPermission) {
            return ['OPS_PROCUREMENT']
        }
        if (hasRelationSalesPermission) {
            return ['SKYCELL_COMMERCIAL']
        }
        return []
    }, [
        hasRelationOpsPermission,
        hasRelationSalesPermission,
    ])

    const fields = useMemo(() => {
        return getActivityRecordFields({
            getAllowedValues,
            exists,
            organizerName: currentUserName,
            companies: (value.companies || []).map(({
                id,
            }) => { return id }),
            participantsTypes,
            disabled,
        })
    }, [
        currentUserName,
        exists,
        getAllowedValues,
        value.companies,
        participantsTypes,
        disabled,
    ])

    const fieldsWithClasses = useFieldsWithClassName(
        fields,
        classes,
    )

    const onChange = useCallback((newValues) => {
        setValue((prev) => {
            if (!isEqual(newValues.companies, prev.companies)) {
                return {
                    ...prev,
                    ...newValues,
                    participants: [],
                }
            }
            return {
                ...prev,
                ...newValues,
            }
        })
    }, [])

    return (
        <FormWithControls
            onSuccess={onSuccess}
            value={value}
            name={AccountManagementRequests.domainName}
            setExternalValue={onChange}
            fields={fieldsWithClasses}
            requestParams={requestParam}
            exists={exists}
            onCancel={onCancel}
            disabled={disabled}
            classNames={{
                gridWrapper: classes.gridWrapper,
                gridCardWrapper: classes.gridCardWrapper,
            }}
            customButtons={customButtons}
        />
    )
}

ActivityRecordForm.defaultProps = defaultProps

export default ActivityRecordForm
