import React from 'react'

import SkyNetTable, {
    SidebarTab,
} from 'app/shared-components/SkyNetTable'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import tableConfig from './serviceCenters.config'
import ServiceCenterCreate from './ServiceCenterCreate'
import ServiceCenterOverview from './ServiceCenterOverview'
import ServiceCenterUpdate from './ServiceCenterUpdate'
import Monitoring from './Monitoring'
import request from './serviceCenters.request'
import {
    ServiceCenterStatus,
} from './serviceCenters.types'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const overviewTab: SidebarTab = {
    url: 'overview',
    label: 'Overview',
    Component: ServiceCenterOverview,
}
const updateTab: SidebarTab = {
    url: 'data-update',
    label: 'Data Update',
    Component: ServiceCenterUpdate,
}
const monitoringTab: SidebarTab = {
    url: 'monitoring',
    label: 'Monitoring',
    Component: Monitoring,
    condition: ({
        serviceCenterStatus,
    }) => {
        return serviceCenterStatus === ServiceCenterStatus.ACTIVE
    },
}

const ServiceCenters = () => {
    const canCreate = useHasPermission(['service_center_create'])
    const canUpdate = useHasPermission(['service_center_update'])
    const canMonitoring = useHasPermission(['service_center_monitoring'])
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: overviewTab,
            permission: true,
        },
        {
            tab: updateTab,
            permission: canUpdate,
        },
        {
            tab: monitoringTab,
            permission: canMonitoring,
        },
    ])

    return (
        <SkyNetTable
            name={tableConfig.name}
            defaultTab="overview"
            tableControls={tableControls}
            tableConfig={tableConfig}
            getDomainObject={request.GetServiceCenterById}
            domainRequestKey={RequestKeys.getServiceCenterById}
            createForm={canCreate ? ServiceCenterCreate : undefined}
            tabs={tabs}
            showSearch
        />
    )
}

export default ServiceCenters
