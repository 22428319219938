import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        onInfo: {
            display: 'grid',
            gridGap: 20,
        },
    }
})

export default useStyles
