import {
    makeStyles,
} from 'app/tss'

const useStyles = makeStyles()(() => {
    return {
        chart: {
            width: '100%',
            height: '575px',
        },
        fullscreen: {
            position: 'absolute',
            height: '85%',
            width: '100%',
        },
    }
})

export default useStyles
